@import '../stylesheets/breakpoints.scss';

button {
  margin: 0;
}

.btn_submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);
  border-radius: 36px;
  color: #ffffff;
  font-family: var(--font);
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  font-size: var(--button-font-size);
  background-color: var(--button-color-active);
  border: 2px solid transparent;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
}
.btn_submit:hover {
  background-color: var(--button-color-background-hover);
}
.btn_submit_inactive {
  cursor: default;
  pointer-events: none;
  background-color: var(--button-color-inactive);
}


.btn_submit_secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);
  border-radius: 36px;
  border: 2px solid var(--button-color-border);
  font-family: var(--font);
  font-weight: 500;
  font-size: 20px;
  color: var(--button-color-border);
  text-align: center;
  font-size: var(--button-font-size);
  background-color: #ffffff;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
}

.btn_label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font);
  font-weight: 500;
  font-size: 18px;
  color: var(--button-color-border);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn_light {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 35px;
  margin: 0;
  border-radius: 36px;
  border: 2px solid transparent;
  background-color: rgb(255 255 255 / 36%);
  cursor: pointer;
  width: fit-content;
  text-align: center;
  color: white;
  font-family: var(--font);
  font-weight: 500;
  font-size: 18px;
}

.btn_gray {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);
  margin: 0;
  border-radius: 36px;
  border: 2px solid var( --button-color-background-2);
  font-family: var(--font);
  color: white;
  letter-spacing: 0;
  text-align: center;
  font-size: var(--button-font-size);
  background-color: var( --button-color-background-2);
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
}

.btn_white_transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  border-radius: 36px;
  border: 2px solid white;
  background-color: transparent;
  cursor: pointer;
  width: fit-content;
  text-align: center;
  color: white;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14;
  letter-spacing: 22;
}
.btn_white_transparent:hover {
  background-color: #ffffff2a;
}

.tab_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  padding: 4px;
  background-color: #EDECEC;
  border-radius: 10px;
  width: 50%;
  margin-bottom: 15px;
  position: relative;
}
.tab_slider {
  position: absolute;
  left: 0%;
  margin: 0 4px;
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  border-radius: 7px;
  background-color: white;
  box-shadow: 0px 0px 6px #4C4C4C29;
  transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  -ms-transition:  0.15s ease-in-out;
  -o-transition:  0.15s ease-in-out;
  -webkit-transition: 0.15s ease-in-out;
}
.tab_value {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 7px;
  padding: 5px;
  height: calc(100% - 10px);
  width: 100%;
  box-shadow: none;
  z-index: 10;
  cursor: pointer;
}

.icon_arrow {
  width: 13px;
  padding: 5px;
  cursor: pointer;
  margin-right: 15px;
}

@media (max-width: $breakpoint-desktop-small) {
  .tab_wrapper {
    margin-bottom: 12px;
  }
}

@media (max-width: $breakpoint-phone) {
  // .btn_submit, .btn_submit_secondary, .btn_gray {
  //   padding: 10px;
  // }
  .btn_submit_secondary {
    font-size: 14px;
    padding: 18px;
  }
  .btn_label {
    font-size: 16px;
  }
  .btn_light {
    padding: 8px 20px;
  }
  .tab_wrapper {
    margin-bottom: 0px;
  }
  .icon_arrow {
    width: 13px;
    padding: 5px;
  }
}

@media (max-width: $breakpoint-phone-small) {
  .btn_submit {
    font-size: 18px;
  }
  .btn_label {
    font-size: 16px;
  }
}
