@import '../stylesheets/breakpoints.scss';


label {
  color: var(--label-color);
  font-family: var(--font);
}

.lbl_invalid {
  color: var(--label-color-invalid);
}

.lbl_active {
  color: var(--label-color-active);
}

.lbl_light {
  color: var(--label-color-light);
}

.lbl_white {
  color: var(--label-color-white);
}

.lbl_black {
  color: var(--label-color);
}

// .lbl_regular {
//   font-family: var(--font);

//   &--regular {
//     font-weight: 400;
//   }

//   &--medium {
//     font-weight: 500;
//   }

//   &--black {
//     font-weight: 700;
//   }
// }


.lbl_regular_18pt {
  font-size: 18pt;
  font-weight: var(--regular-font-weight);
}

.lbl_regular_8 {
  font-weight: 500;
  font-size: 8px;
}
.lbl_regular_10 {
  font-weight: 700;
  font-size: 10px;
}
.lbl_regular_12 {
  font-weight: 400;
  font-size: 12px;
}
.lbl_regular_14 {
  font-weight: 400;
  font-size: 14px;
}
.lbl_regular_16 {
  font-weight: 400;
  font-size: 16px;
}
.lbl_regular_18 {
  font-weight: 400;
  font-size: 18px;
}
.lbl_regular_20 {
  font-weight: 400;
  font-size: 20px;
}
.lbl_regular_22 {
  font-weight: 400;
  font-size: 22px;
}
.lbl_regular_24 {
  font-weight: 400;
  font-size: 24px;
}

.lbl_medium_14 {
  font-weight: 500;
  font-size: 14px;
}
.lbl_medium_16 {
  font-weight: 500;
  font-size: 16px;
}
.lbl_medium_18 {
  font-weight: 500;
  font-size: 18px;
}
.lbl_medium_20 {
  font-weight: 500;
  font-size: 20px;
}
.lbl_medium_22 {
  font-weight: 500;
  font-size: 22px;
}
.lbl_medium_24 {
  font-weight: 500;
  font-size: 24px;
}
.lbl_medium_26 {
  font-weight: 500;
  font-size: 26px;
}
.lbl_medium_28 {
  font-weight: 500;
  font-size: 28px;
}


.lbl_black_14 {
  font-weight: 700;
  font-size: 14px;
}
.lbl_black_16 {
  font-weight: 700;
  font-size: 16px;
}
.lbl_black_28 {
  font-weight: 700;
  font-size: 28px;
}
.lbl_black_32 {
  font-weight: 700;
  font-size: 32px;
}
.lbl_black_80 {
  color: white;
  font-weight: 800;
  font-size: 80px;
}

.lb_regular_menu {
  font-weight: 400;
  font-size: 20px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .lb_regular_menu {
    font-size: 11px;
  }
  .lbl_regular_20 {
    font-size: 18px;
  }
  .lbl_black_14 {
    font-size: 16px;
  }
}

@media only screen and (max-width: $breakpoint-phone) {
  .lb_regular_menu {
    font-size: 9px;
  }

  .lbl_regular_14 {
    font-size: 12px;
  }
  .lbl_regular_16 {
    font-size: 14px;
  }
  .lbl_regular_20 {
    font-size: 16px;
  }
  .lbl_regular_24 {
    font-size: 20px;
  }

  .lbl_medium_18 {
    font-size: 16px;
  }
  .lbl_medium_24 {
    font-size: 18px;
  }
  .lbl_medium_20 {
    font-size: 18px;
  }
  .lbl_medium_22 {
    font-size: 18px;
  }
}

@media only screen and (max-width: $breakpoint-phone)  {
  .lbl_medium_28 {
    font-size: 22px;
  }
  .lbl_black_80 {
    font-size: 52px;
  }
  .lbl_black_28 {
    font-size: 22px;
  }
}




