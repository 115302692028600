@import '../stylesheets/breakpoints.scss';

.checkbox_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  column-gap: 10%;
}

.checkbox {
  border: 2px solid var(--checkbox-color-border);
  border-radius: 50%;
  padding: 5px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background-color: var(--checkbox-color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}
.checkbox_checked {
  border-color: var(--checkbox-color-border-active);
}
.checkbox > img {
  width: 20px;
}
