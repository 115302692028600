@import '../stylesheets/breakpoints.scss';

.view_container {
  position: absolute;
  top: 0;
  left: 25vw;
  min-width: 75vw;
  overflow: hidden;
}

.header_container {
  position: absolute;
  top: 0;
  min-width: calc(100% - 100px);
  min-height: var(--header-height);
  padding: 0px 50px;
  box-shadow: var(--box-shadow-menu);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.header_container_active {
  box-shadow: var(--box-shadow-menu);
}

.view_body {
  padding: 60px;
  margin-top: var(--header-height);
  height: calc(var(--view-body-vh) - 60px);
  overflow-y: scroll;
  background-size: cover;
  overflow-x: hidden;
  scrollbar-width: none !important;
}

.form_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
}

.iframe_wrapper {
  width: 100%;
  height: 100%;
  height: 80vh;
}


// @media only screen and (max-height: 850px) {
//   .view_body {
//     padding: 30px;
//     height: calc(100vh - 60px - 90px);
//   }
// }

@media only screen and (max-width: $breakpoint-desktop-small) {
  .view_body {
    padding: 60px;
    height: calc(100vh - 120px - 90px);
  }
}

@media only screen and (max-width: 1650px) {
  .form_profile {
    width: 50%;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .view_container {
    left: 0;
    min-width: 100%;
  }
  .header_container {
    position: absolute;
    min-width: calc(100% - 50px);
    min-height: var(--header-tablet-height);
    padding: 0px 25px;
    box-shadow: none;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .view_body {
    padding: 30px 30px 50px 30px;
    margin-top: var(--header-tablet-height);
    height: calc(100vh - var(--header-tablet-height) - var(--sidebar-tablet-height) - 80px);
  }
  .form_profile {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .form_profile {
    width: 80%;
  }
}

@media only screen and (max-width: $breakpoint-phone) {
  .header_container {
    min-height: var(--header-phone-height);
  }
  .view_body {
    margin-top: var(--header-phone-height);
    padding: 15px 20px;
    height: calc(var(--view-body-phone-vh) - 30px);
    overflow-y: scroll;
  }
  .form_profile {
    width: 90%;
  }
}
