@import '../stylesheets/breakpoints.scss';


input {
  border: none;
  outline: none;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: var(--input-padding);
  background-color: transparent;
  font-family: var(--font);
  font-size: 16px;
  color: var(--input-color-text);
}
input::placeholder {
  color: var(--input-color-placeholder);
  font-family: var(--font);
  font-size: 16px;
}

.input_container_one {
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}
.input_container_one_invalid {
  border-color: var(--color-invalid);
}
.inputfield_container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-color-border);
  padding: 5px;
  background-color: var(--input-color-background);
  width: -webkit-fill-available;
  width: -moz-available;
}
.inputfield_container:focus-within {
  border-color: var(--input-color-focus);
}
.inputfield_container_date {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-color-border);
  padding: 5px;
  background-color: var(--input-color-background);
  width: -webkit-fill-available;
  width: -moz-available;
}
.inputfield_container_invalid {
  border-color: var(--color-invalid)
}
.inputfield_container_invalid:focus-within {
  border-color: var(--color-invalid);
}
.inputfield {
  border-radius: 5px;
  border: 2px solid transparent;
  background-color: transparent;
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  width: -webkit-fill-available;
  width: -moz-available;
}
.input_valid_indicator {
  position: relative;
  left: -4%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-valid);
}
.input_valid_indicator_invalid {
  background-color: var(--color-invalid);
}

.inputfield_date {
  border: none;
  outline: none;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: transparent;
  font-family: var(--font);
  font-size: 16px;
  color: var(--input-color-text);
  height: 36px;
}

.icon_password {
  position: relative;
  left: -8%;
  width: 15px;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
}
.input_icon {
  position: relative;
  left: -8%;
  width: 15px;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
}

.input_container_one_icon {
  width: 28px;
  height: 28px;
  // margin-right: 18px;
  margin-right: 10px;
}

.inputfield_container_search {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-color-border);
  padding: 5px;
  background-color: var(--input-color-background);
  width: -webkit-fill-available;
  width: -moz-available;
}

.input_disabled {
  background: #c0c0c26e;
}
.input_disabled > input {
  color: #a8a8aaf1;
}

@media (max-width: $breakpoint-phone) {
  input {
    padding: 2px 15px;
    font-size: 14px;
  }
  input::placeholder {
    font-size: 14px;
  }
  .input_container_one_icon {
    width: 22px;
    height: 22px;
    margin-right: 13px;
  }
}


@media screen and (max-width: $breakpoint-phone) {
  input, select, textarea {
    font-size: 16px !important;
  }
}
