@import '../src/stylesheets/buttons.scss';
@import '../src/stylesheets/labels.scss';
@import '../src/stylesheets/inputs.scss';
@import '../src/stylesheets/checkbox.scss';
@import '../src/stylesheets/container.scss';
@import '../src/stylesheets/dropdown.scss';
// @import url("https://p.typekit.net/p.css?s=1&k=whc1gay&ht=tk&f=24537.24545.24547&a=37001785&app=typekit&e=css");
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');


:root {
  /* fonts */
  --font: 'Montserrat', sans-serif;

  /* labels */
  --regular-font-weight: 400;
  --medium-font-weight: 500;

  --color-invalid: #FF2E83;
  --color-valid: #C4EB98;
  --color-valid-2: #5EDAAC;

  /* button */
  --button-color-active: #5EDAAC;
  --button-color-inactive: #6A6A6A;
  --button-color-border: #5EDAAC;
  --button-padding: 15px;
  --button-padding-light: 5px;
  --button-color-background-hover: #5edaadd7;
  --button-color-background-2: #C0C0C2;
  --button-font-weight: 400;
  --button-font-size: 20px;
  --button-font-size-light: 18px;
  --button-label-font-size: 18px;
  --button-label-font-size: 18px;

  /* input */
  --input-color-background: #F9F9F9;
  --input-color-placeholder: #C0C0C2;
  --input-color-border: #EDECEC;
  --input-color-text: #4C4C4C;
  --input-color-valid: #5EDAAC;
  --input-color-invalid: #FF2E83;
  --input-color-focus: #5EDAAC;
  --input-border-radius: 25px;
  --input-padding: 5px 25px;
  --input-font-size: 16px;

  /* label */
  --label-color: #4C4C4C;
  --label-color-invalid: #FF2E83;
  --label-color-active: #5EDAAC;
  --label-color-light: #C0C0C2;
  --label-color-white: #ffffff;

  /* checkbox */
  --checkbox-color-background: tranparent;
  --checkbox-color-border: #4C4C4C;
  --checkbox-color-border-active: #5EDAAC;
  --checkbox-font-size: 14px;
  --checkbox-font-weight: 400;

  --box-shadow-menu: 3px 0px 15px #4C4C4C29;

  --tile-color-1: #6A6A6A;
  --tile-color-2: #6A6A6A;
  --tile-color-3: #6A6A6A;
  --tile-color-4: #6A6A6A;

  /* sidebar */
  --sidebar-tablet-height: 75px;
  --sidebar-phone-height:  calc((var(--vh) * 10) - 10px);

  /* header */
  --header-height: 105px;
  --header-tablet-height: 75px;
  --header-phone-height: 60px;

  /* body */
  --view-body-vh: calc(calc(var(--vh) * 100) - var(--header-height));
  --view-body-phone-vh: calc(calc(var(--vh) * 100) - var(--sidebar-phone-height) - var(--header-phone-height));

  --width-tablet: 1180px;

  //Scrollbar Firefox
  scrollbar-color: var(--color-valid-2) transparent !important;
  scrollbar-width: thin !important;
  overflow-x: hidden;
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  font-family: var(--font);
}

//Scrollbar Chrome
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background: transparent;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: transparent;
  height: 100px;
}
::-webkit-scrollbar-thumb {
  background: var(--label-color-light);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--label-color-light);
}
::-webkit-scrollbar-corner{
  background: transparent;
}


// @font-face {
//   font-family:"sofia-pro";
//   src:url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//   font-display:auto;
//   font-style:normal;
//   font-weight:400;
//   font-stretch:normal;
// }

//https://css-tricks.com/how-to-tame-line-height-in-css/

