@import '../stylesheets/breakpoints.scss';

.dropdown_cotainer {
  position: absolute;
  background-color: white;
  box-shadow: var(--box-shadow-menu);
  background-color: var(--input-color-background);
  border-radius: var(--input-border-radius);
  padding: 20px 0px;
  top: 48px;
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  z-index: 100;
}

.dropdown_element_wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.dropdown_scroll_wrapper {
  width: calc(100% - 5px);
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin !important;
  scroll-behavior: smooth;
}
.dropdown_element {
  display: flex;
  align-items: flex-start;
  padding: 5px 25px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
}
.dropdown_element:hover {
  color: white;
  background-color: var(--button-color-background-hover);
}
.dropdown_element_active {
  color: white;
  background-color: var(--button-color-background-hover);
}

.dropdown_element_icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}


@media only screen and (max-width: $breakpoint-tablet) {
  .dropdown_cotainer {
    top: 35px;
  }
  .dropdown_scroll_wrapper {
    max-height: 130px;
  }
}

@media only screen and (max-width: $breakpoint-phone) {
  .dropdown_cotainer {
    top: 45px;
  }
  .dropdown_element {
    display: flex;
    align-items: center;
    padding: 5px 25px;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }

  .dropdown_element_icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
